<template>
	<div class="content-wrap">
		<!--<h1>结题统计报表</h1>-->
		<Spin fix v-if="loading"></Spin>
		<CtmsAction>
			<Button v-if="data.length" @click="exportData">导出结题统计报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="getTableCols"
			:data="data"
			:page="page"
			:showIndex="false"
			ref="table"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/report/report"
import { mapState } from "vuex"

const { apiGetConclusion } = api
export default {
	name: "conclusion",
	data() {
		return {
			loading: false,
			headers: [],
			data: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 55
		}),
		getTableCols() {
			return this.headers.map(item => Object.assign({ minWidth: 150 }, item))
		}
	},
	mounted() {
		this.initData()
	},
	methods: {
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetConclusion({
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res && res.list && res.list.length) {
					this.headers = []
					const obj = res.list[0]
					for (const key in obj) {
						this.headers.push({
							title: obj[key],
							key
						})
					}
					this.data = res.list.slice(1)
				}
				this.page.total = res ? res.total : 0
				this.loading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		exportData() {
			window.open(
				`${this.$baseUrl}/report/export-conclusion?token=${this.$store.state.user.token}`
			)
		}
	}
}
</script>
